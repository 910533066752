import isEmpty from 'lodash/isEmpty'
import React from 'react'
import {
  EmailAttributes,
  getAttribute,
} from './constants'

export const CompanyAddress = ({data}: any) => {
  const streetAddress = getAttribute(data, EmailAttributes.companyStreetAddress)
  const cityStateZip = getAttribute(data, EmailAttributes.companyCityStateZip)
  const addressParts = [streetAddress, cityStateZip].filter((a: string) => !isEmpty(a))
  // Ick - can't do this with .map, as I can't inject the JSX element in the array manipulation
  if (addressParts.length === 0) {
    return <span/>
  } else if (addressParts.length === 1) {
    return <span>{addressParts[0]}</span>
  } else {
    return <span>{addressParts[0]}, {addressParts[1]}</span>
  }
}
