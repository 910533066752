import { SelectColor } from 'components/colors/component'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { GeneratedTextModalComponent } from 'components/generate-text'
import { NewScheduledContentIcon } from 'components/icons/new-scheduled-content'
import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { WysiwygEditor } from 'components/wysiwyg/component'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { Button, Input } from 'reactstrap'
import { MYSQL_MEDIUM_TEXT_LENGTH } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { ModalSize } from 'shared/modals/constants'
import { EmailAttributes, leadTextAttributes, leadTextDefaults } from 'shared_server_client/constants'
import { BillingPlanDetailCode, canAccessPlanFeature } from 'shared_server_client/types/billing_plan'
import { PopOut } from '../../icons/popOut';
import { EditSectionProps } from '../components'
import {
  BORDER_FONT_SIZES,
  FONT_SIZES,
  FONT_STYLES,
  FONT_WEIGHTS,
  MenuSections,
} from '../constants'
import '../styles.scss'
import {
  getModuleIndex,
  getStaticTextAttribute,
  getStaticTextStylesAttribute,
  updateStaticTextAttributes,
  updateStaticTextStylesAttribute,
} from '../utils'
import { PopOutModalComponent } from './popOutModalComponent';

interface LTEProps extends EditSectionProps {
  context?: any,
  staticTextType: string,
}

type LeadTextEditorProps = GenericRedux.AllComponentPropsWithModal<any> & LTEProps
export class LeadTextEditor extends React.Component<LeadTextEditorProps, any> {

  public static contextType = RasaContext
  private sharedStore: SharedStore
  private displayHtmlTab: boolean = false
  constructor(props: LeadTextEditorProps) {
    super(props)
    this.state = {
      hasGenerativeAIAccess: false,
      hasScheduledContentAccess: false,
      displayHtmlTab: false,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ]).then(([activeCommunity, person]) => {
      this.setState({
        hasGenerativeAIAccess: canAccessPlanFeature(
          BillingPlanDetailCode.GENERATIVE_AI, activeCommunity.billingInfo.currentPlan),
        hasScheduledContentAccess: canAccessPlanFeature(
          BillingPlanDetailCode.SCHEDULED_CONTENT, activeCommunity.billingInfo.currentPlan),
      })
    })
  }

  public render() {
    const { context } = this.props
    return (
        <div className="static-content-wrapper">
          <div className="go-back" onClick={() => this.goBack()}>&lt; &nbsp; Go back</div>
          <div className="title-description-and-toggle-container">
            <div className="title-and-toggle-container">
              <div className="title">
                {this.isScheduled()
                ? `Scheduled Text / HTML ${context.sequence}`
                : `Text / HTML ${context.sequence}`}
              </div>
              {this.state.hasScheduledContentAccess && this.toggle()}
            </div>
          </div>
          {(this.state.hasScheduledContentAccess && this.isScheduled())
          ? this.scheduledTab()
          : this.nonScheduledTab()}

        </div>
      )
  }

  private toggle() {
    return (
      <div className="toggle-wrapper">
        {!this.isScheduled() &&
        <NewScheduledContentIcon greyScale={true} svgwidth={30} svgheight={30}/>}
        <div className="clickable-item"
            onClick={() => this.toggleScheduled()}>
          {this.isScheduled()
          ? <ToggleOn/>
          : <ToggleOff/>}
        </div>
      </div>
    )
  }

  private scheduledTab() {
    return (
      <div className="scheduled-tab-wrapper">
        <Button className="add-scheduled-content"
          onClick={() => {
            this.props.push(
            DashboardMenuOption.scheduledContent +
            `?action=add&type=text&location=Text${this.props.context.sequence}`)}}>
          + Add Scheduled Content
        </Button>
      </div>
    )
  }

  private nonScheduledTab() {
    const { data, staticTextType } = this.props
    const displayHtmlTab = this.displayHtmlTab
    this.displayHtmlTab = false
    return <div className="non-scheduled-tab-wrapper">
            <div className="description">
              <span>
                Enter your copy
              </span>
              {this.state.hasGenerativeAIAccess &&
              <span>
                &nbsp;or
                <Button onClick={this.openChatGPTModal}>
                  <span>Generate with ChatGPT</span>
                </Button>
                <div className="generate-text">
                  <GeneratedTextModalComponent
                    data={this.props.modals}
                    closeModal={this.props.closeModal}
                    saveModal={(key, modalData) => {
                      if ( modalData.description ) {
                        this.setState({
                          displayHtmlTab: true,
                        }, () => {
                          this.displayHtmlTab = true
                          const changes: any = {
                            [leadTextAttributes.text]: modalData.description,
                            [leadTextAttributes.isHtmlEdited]: false,
                          }
                          this.onStaticTextChange(
                            updateStaticTextAttributes(data.static_texts, staticTextType, changes))
                        })
                      }
                      return this.props.closeModal(key)
                    }}
                    updateModal={this.props.updateModal}
                    size={ModalSize.Large}/>
                  </div>
                </span>}
              </div>
              <div className="textEditorSection">
                <span onClick={this.popOutModal} className="iconPopout"><PopOut svgwidth={15} svgheight={15}/></span>
                <PopOutModalComponent
                    data={this.props.modals}
                    html={getStaticTextAttribute(
                        data, staticTextType, leadTextAttributes.text, leadTextDefaults.text)}
                    closeModal={this.props.closeModal}
                    saveModal={(key, modalData) => {
                      const changes: any = {
                        [leadTextAttributes.text]: modalData.description,
                      }
                      this.onStaticTextChange(
                          updateStaticTextAttributes(data.static_texts, staticTextType, changes))
                      return this.props.closeModal(key)
                    }}
                title={`Text / HTML ${this.props.context.sequence}`}/>
                <WysiwygEditor
                  showCharacterCount={true}
                  maxCharacterCount={MYSQL_MEDIUM_TEXT_LENGTH}
                  onTabChange={(tab: string) => this.setState({displayHtmlTab: false})}
                  displayHtmlTab={displayHtmlTab}
                  htmlEdited={getStaticTextAttribute(
                    data, staticTextType, leadTextAttributes.isHtmlEdited, leadTextDefaults.isHtmlEdited)}
                  html={getStaticTextAttribute(
                    data, staticTextType, leadTextAttributes.text, leadTextDefaults.text)}
                  onChange={(html: string, isHtmlEdited: boolean) => {
                    this.onStaticTextChange(updateStaticTextAttributes(
                      data.static_texts, staticTextType, {
                        [leadTextAttributes.text]: html,
                        [leadTextAttributes.isHtmlEdited]: isHtmlEdited,
                      }))
                  }}/>
                <div className="text-border">
                  <br/>
                  <div className="title">Border</div>
                  <div className="flex">
                    <Input type="select"
                        className="static-dropdown margin-right-10"
                        value={getStaticTextStylesAttribute(
                          data, staticTextType, leadTextAttributes.borderWidth, leadTextDefaults.borderWidth)}
                        onChange={(e) => {
                          e.persist()
                          this.onStaticTextChange(updateStaticTextStylesAttribute(
                          data, staticTextType, leadTextAttributes.borderWidth, e.target.value))}}>
                      {BORDER_FONT_SIZES.map((s: string) => <option key={s} value ={s}>{s}</option>)}
                    </Input>
                    <SelectColor className={null}
                      color={getStaticTextStylesAttribute(
                      data, staticTextType, leadTextAttributes.borderColor, leadTextDefaults.borderColor)}
                      selectColor={(c: string) => this.onStaticTextChange(updateStaticTextStylesAttribute(
                      data, staticTextType, leadTextAttributes.borderColor, c))}/>
                  </div>
                </div>
                <div className="text-border">
                  <br/>
                  <div className="title">Default Font Styles</div>
                  <div className="flex">
                    <Input type="select"
                        className="static-dropdown margin-right-10"
                        value={getStaticTextStylesAttribute(
                        data, staticTextType, leadTextAttributes.fontSize, leadTextDefaults.fontSize)}
                        onChange={(e) => {
                          e.persist()
                          this.onStaticTextChange(updateStaticTextStylesAttribute(
                          data, staticTextType, leadTextAttributes.fontSize, e.target.value))}}>
                    {FONT_SIZES.map((s: string) => <option key={s} value ={s}>{s}</option>)}
                    </Input>
                    <SelectColor className={null}
                      color={getStaticTextStylesAttribute(
                      data, staticTextType, leadTextAttributes.color, leadTextDefaults.color)}
                      selectColor={(c: string) => this.onStaticTextChange(updateStaticTextStylesAttribute(
                      data, staticTextType, leadTextAttributes.color, c))}/>
                  </div>
                  <div className="flex">
                    <Input type="select"
                      className="static-dropdown margin-right-10"
                      value={getStaticTextStylesAttribute(
                      data, staticTextType, leadTextAttributes.fontWeight, leadTextDefaults.fontWeight)}
                      onChange={(e) => {
                        e.persist()
                        this.onStaticTextChange(updateStaticTextStylesAttribute(
                        data, staticTextType, leadTextAttributes.fontWeight, e.target.value))}}>
                    {FONT_WEIGHTS.map((s: string) => <option key={s} value ={s}>{s}</option>)}
                    </Input>
                    <Input type="select"
                      className="static-dropdown"
                      value={getStaticTextStylesAttribute(
                      data, staticTextType, leadTextAttributes.fontStyle, leadTextDefaults.fontStyle)}
                      onChange={(e) => {
                        e.persist()
                        this.onStaticTextChange(updateStaticTextStylesAttribute(
                        data, staticTextType, leadTextAttributes.fontStyle, e.target.value))}}>
                      {FONT_STYLES.map((s: string) => <option key={s} value ={s}>{s}</option>)}
                    </Input>
                  </div>
                </div>
              </div>
          </div>
  }

  private openChatGPTModal = () => {
    this.props.openModal(GeneratedTextModalComponent.key, {source: 'layout'})
  }

  private popOutModal = () => {
    this.props.openModal(PopOutModalComponent.key, {source: 'layout'})
  }

  private goBack() {
    this.props.select(MenuSections.layout)
  }

  private onStaticTextChange(newStaticText) {
    const {data, staticTextType} = this.props
    const newStaticTexts = {
      ...data.static_texts,
      [staticTextType]: newStaticText,
    }
    this.props.onChange(EmailAttributes.staticTexts, newStaticTexts)
  }

  private toggleScheduled() {
    // find module index
    const moduleIndex = getModuleIndex(
      this.props.data.template_modules,
      'leadText',
      this.props.context.sequence,
    )
    // add/edit 'isScheduled' prop
    const module = this.props.data.template_modules[moduleIndex]
    const updatedModule = {
      ...module,
      moduleConfig: { isScheduled: !module.moduleConfig.isScheduled },
    }
    // update global props template_modules with new module
    const updatedModules = [...this.props.data.template_modules]
    updatedModules.splice(moduleIndex, 1, updatedModule)
    this.props.onChange('template_modules', updatedModules)
  }

  private isScheduled(): boolean {
    const moduleIndex = getModuleIndex(
      this.props.data.template_modules,
      'leadText',
      this.props.context.sequence,
    )
    const module = this.props.data.template_modules[moduleIndex]
    return module.moduleConfig.isScheduled
  }

}
