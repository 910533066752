
import { sizedUrl} from 'app/utils'
import {ImageUploadError} from 'components/common/constants'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { NewScheduledContentIcon } from 'components/icons/new-scheduled-content'
import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { Upload } from 'components/icons/upload2'
import { Loading, SIZES } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { createRef } from 'react'
import Dropzone from 'react-dropzone'
import { Button, Input} from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { IMAGE } from 'shared_server_client/constants'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { EditSectionProps } from '../components'
import { ContextType, MenuSections, StaticImageType } from '../constants'

import {
  getModuleIndex,
  getStaticImageAttribute,
  staticImageDBNameMapToUIType,
  updateStaticImageProperty,
  updateStaticImages } from '../utils'

import '../styles.scss'

interface UploadImageState {
  error?: boolean,
  hasFeatureAccess: boolean,
  loading: boolean,
  image?: string,
  hosted_image_url: string,
  source_image_url?: string,
  link_to_image_url?: string,
  invalidImageMsg?: string,
}

const initialState: UploadImageState = {
  error: false,
  hasFeatureAccess: false,
  loading: false,
  image: '',
  hosted_image_url: '',
  source_image_url: '',
  link_to_image_url: '',
  invalidImageMsg:  null,
}

interface UIProps extends EditSectionProps {
  context?: ContextType,
}

type UploadImageProps = GenericRedux.AllComponentPropsWithModal<any> & UIProps

const dropzoneRef: any = createRef()

export class UploadImage extends React.Component<UploadImageProps, UploadImageState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private communityId: string = null

  constructor(props: UploadImageProps) {
    super(props)
    this.state = initialState
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ]).then(([activeCommunity, person]) => {
      this.communityId = activeCommunity.communityId
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.setState({
        hasFeatureAccess: avlFeatures.indexOf(BillingPlanDetailCode.SCHEDULED_CONTENT) > -1,
      })
    })
  }

  public render() {
    return (
      <div className="static-content-wrapper">
        <div className="go-back" onClick={() => this.goBack()}>&lt; &nbsp; Go back</div>
        <div className="title-description-and-toggle-container">
          <div className="title-and-toggle-container">
            <div className="title">
              {this.isScheduled()
              ? `Scheduled ${this.getImageDisplayName()}`
              : this.getImageDisplayName()
              }
            </div>
            {this.state.hasFeatureAccess && this.toggle()}
          </div>
        </div>
        {(this.state.hasFeatureAccess && this.isScheduled()) ? this.scheduledTab() : this.nonScheduledTab()}
      </div>
    )
  }

  private getImageDisplayName() {
    return this.props.context.name
  }

  private toggle() {
    return (
      <div className="toggle-wrapper">
        {!this.isScheduled() &&
        <NewScheduledContentIcon greyScale={true} svgwidth={30} svgheight={30}/>}
        <div className="clickable-item"
            onClick={() => this.toggleScheduled()}>
          {this.isScheduled()
          ? <ToggleOn/>
          : <ToggleOff/>}
        </div>
      </div>
    )
  }

  private scheduledTab() {
    return (
      <div className="scheduled-tab-wrapper">
        <Button className="add-scheduled-content"
          onClick={() => this.props.push(DashboardMenuOption.scheduledContent +
          `?action=add&type=image&location=${this.props.context.name}`)}>
          + Add Scheduled Content
        </Button>
      </div>
    )
  }

  private nonScheduledTab() {
    const { context } = this.props
    return <div className="non-scheduled-tab-wrapper">
            <div className="section">
              <div className="description">
                {context.width}px Recommended Width
              </div>
              <div className="image-container">
              {this.state.loading ? <Loading size={SIZES.LARGE}/> :
              <Dropzone onDrop={(acceptedFiles) => this.uploadImage(acceptedFiles[0], context)}
                        accept="image/*"
                        ref={dropzoneRef}>
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()} className="lined-box clickable-item">
                        <input {...getInputProps()} />
                        <div className="image-or-placeholder-wrapper">
                          {this.hostedImageUrl(context) ?
                          <img src={sizedUrl(this.hostedImageUrl(context), 200, 200)}/> : null}
                          {!this.hostedImageUrl(context) ?
                          <div className="placeholder-wrapper">
                            <div className="upload-wrapper">
                              <div className="upload"><Upload/></div>
                            </div>
                            <p className="row1-text">Choose your file</p>
                            <p className="row2-text">You may drag and drop your file as well.</p>
                            <p className="row3-text">Upload only PNG, JPG.</p>
                          </div> : null}
                        </div>
                        <div>
                        {this.state.invalidImageMsg &&
                          <div className="Image-Upload-Size-ErrorMsg">
                            {this.state.invalidImageMsg}
                          </div>
                        }
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>}
              </div>
              <div className="put-flex">
                <div className="delete-image-button clickable-item"
                      onClick={() => this.deleteImage(context)}>
                  Delete
                </div>
                <div className="change-image-button"
                      onClick={this.openDialog}>
                  Change
                </div>
              </div>
            <div className="bottom-wrapper">
              <div className="link-to-title">
                Link to
              </div>
              <div className="link-to-description">
                Enter the URL you want this image to link to.
              </div>
              <div className="url-rectangle-wrapper">
                <div className="url-left-element">
                  <div className="url-left-element-text">
                    URL
                  </div>
                </div>
                <div className="url-right-element">
                <Input value={getStaticImageAttribute(this.props.data, context.id, 'url', '')}
                      onChange={(e) =>
                        this.props.onChange('static_images', updateStaticImageProperty(this.props.data.static_images,
                           context.id, 'url', e.target.value))}/>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
  }

  private openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
    this.setState({invalidImageMsg:  null})
  }

  private deleteImage = (st: StaticImageType) => {
    this.props.onChange('static_images', updateStaticImages(this.props.data.static_images, st.id,
      {
        hosted_image_url: '',
        is_active: false,
        name: '',
        static_image_type_id: null,
        url: '',
      }))
    this.setState({invalidImageMsg: null})
  }

  private hostedImageUrl = (st: StaticImageType): string => {
    const staticImages = this.props.data.static_images
    if (staticImages) {
      const imageUrl = staticImages[st.id]
        ? staticImages[st.id].hosted_image_url
        : ''
      return imageUrl
    } else {
      return ''
    }

  }

  private goBack() {
    this.props.select(MenuSections.layout)
  }

  private uploadImage(image: any, st: StaticImageType) {
    this.setState({
      loading: true,
      invalidImageMsg:  null,
    })
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage) => {
        this.setState({
          loading: false,
        })
        this.props.onChange('static_images', updateStaticImages(this.props.data.static_images, st.id,
          {
            hosted_image_url: hostedImage.url,
            is_active: true,
            name: st.name,
            static_image_type_id: st.id,
            url: this.props.data.static_images[st.id] ? this.props.data.static_images[st.id].url : '',
            height: hostedImage.height,
            width: hostedImage.width,
          }))

        this.setState({
          invalidImageMsg: hostedImage.width > this.props.context.width ? ImageUploadError.LargeImage : null,
        })
      })
      .catch((error) => {
        this.setState({
          error: true,
          loading: false,
        })
      })
  }

  private toggleScheduled() {
    // find module index
    const moduleIndex = getModuleIndex(
      this.props.data.template_modules,
      staticImageDBNameMapToUIType(this.props.context.name),
      this.props.context.sequence,
    )
    // add/edit 'isScheduled' prop
    const module = this.props.data.template_modules[moduleIndex]
    const updatedModule = {
      ...module,
      moduleConfig: { isScheduled: !module.moduleConfig.isScheduled },
    }
    // update global props template_modules with new module
    const updatedModules = [...this.props.data.template_modules]
    updatedModules.splice(moduleIndex, 1, updatedModule)
    this.props.onChange('template_modules', updatedModules)
  }

  private isScheduled(): boolean {
    const moduleIndex = getModuleIndex(
      this.props.data.template_modules,
      staticImageDBNameMapToUIType(this.props.context.name),
      this.props.context.sequence,
    )
    const module = this.props.data.template_modules[moduleIndex]
    return module.moduleConfig.isScheduled
  }
}
