import { RasaContext } from 'context'
import * as React from 'react'
import * as Router from 'router'

import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { DropdownComponent } from 'components/dropdown/component'
import { EmailSent } from 'components/icons/emailSent'
import { BigAndBoldTemplate } from 'components/icons/template-big-and-bold'
import { ClassicTemplate } from 'components/icons/template-classic'
import { InsightsTemplate } from 'components/icons/template-insights'
import { NewspaperTemplate } from 'components/icons/template-newspaper'
import { Dataset } from 'generic/dataset'
import isNil from 'lodash/isNil'
import { connect } from 'react-redux'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { EmailAttributes, EmailTemplates } from 'shared_server_client/constants'
import { getAttribute, UpgradeTemplateField, UpgradeTemplateFieldVal } from '../constants'

import {CustomTemplateUnavailableComponent} from '../../custom-template-unavailable/component';
import {Upgrade} from '../../icons/upgrade';
import { EditSectionProps } from '../components'
import { Roles } from 'shared/constants'

const NO_TEMPLATE_SELECTED = '-1'
const NO_TEMPLATE_SELECTED_LABEL = 'Select'
interface TemplateEditProps {
  isCustomTemplateAllowed?: boolean
  push?: any
  setConfigData?: any
}
interface TemplateEditState {
  communityId: string,
  emailTemplates: any[],
  emailTemplatesdata: any[],
  isSuperUser: boolean,
  selected: string,
  showList: boolean,
  selectedCode?: any,
  communityPartnerData?: any,
}
type TemplateEditComponentProps = TemplateEditProps & EditSectionProps

export class TemplateEditComponent extends React.Component<TemplateEditComponentProps, TemplateEditState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore
  constructor(props: EditSectionProps) {
    super(props)
    this.state = {
      communityId: '',
      emailTemplates: [],
      emailTemplatesdata: [],
      isSuperUser: false,
      selected: null,
      showList: false,
    }
  }
  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
    ])
    .then(([activeCommunity, role]) => {
      this.setState({
        communityId: activeCommunity.communityId,
        isSuperUser: role === Roles.super_admin,
      })
      this.loadEmailTemplates()
    })
  }
  public render() {
    return <div className="template-editor">
      <span className="title">Templates</span>
      {getAttribute(this.props.data, EmailAttributes.emailTemplate) &&
        (Object.values(EmailTemplates).includes(
        getAttribute(this.props.data, EmailAttributes.emailTemplate)) ||
        getAttribute(this.props.data, EmailAttributes.emailTemplate).indexOf('custom') > -1) ?
      <div className="section">
        <div className="small-title-description">Select a template from below and begin designing.</div>
        <div className="block template">
          <div className="title">
          </div>
          <button className={this.buttonClass([EmailTemplates.newspaper, EmailTemplates.oldNewspaper], 'news')}
            onClick={(e) => this.props.onChange(EmailAttributes.emailTemplate, EmailTemplates.newspaper)}>
            <NewspaperTemplate colors={(this.state.communityPartnerData) ?
            {...this.props.data, ...this.state.communityPartnerData} : this.props.data}/><br/>
            <div className="templatetitle">
              Newspaper
              <span className="descriptionoftem">
                A newspaper style newsletter template. Includes a large first article.
              </span>
            </div>
          </button>
          <button className={this.buttonClass([EmailTemplates.default, EmailTemplates.oldDefault], 'classic')}
            onClick={(e) => this.props.onChange(EmailAttributes.emailTemplate, EmailTemplates.default)}>
            <ClassicTemplate colors={(this.state.communityPartnerData) ?
            {...this.props.data, ...this.state.communityPartnerData} : this.props.data}/><br/>
            <div className="templatetitle">Classic
              <span className="descriptionoftem">
                A classic. Effective and simple. Includes small image articles.
              </span>
            </div>
          </button>
          <button className={this.buttonClass([EmailTemplates.bigAndBold, EmailTemplates.oldBigAndBold], 'bold')}
            onClick={(e) => this.props.onChange(EmailAttributes.emailTemplate, EmailTemplates.bigAndBold)}>
            <BigAndBoldTemplate colors={(this.state.communityPartnerData) ?
            {...this.props.data, ...this.state.communityPartnerData} : this.props.data}/><br/>
            <div className="templatetitle">Big and Bold
              <span className="descriptionoftem">Large size article images and bolder fonts.</span>
            </div>
          </button>
          <button className={this.buttonClass([EmailTemplates.insights], 'insights')}
            onClick={(e) => this.props.onChange(EmailAttributes.emailTemplate, EmailTemplates.insights)}>
            <InsightsTemplate colors={(this.state.communityPartnerData) ?
            {...this.props.data, ...this.state.communityPartnerData} : this.props.data}/><br/>
            <div className="templatetitle">Insights
              <span className="descriptionoftem">Focused on text and information.</span>
            </div>
          </button>
          <button className={this.buttonClass(['custom'], 'custom')} onClick={this.onCustomTemplateClick}>
            {this.props.isCustomTemplateAllowed ?
              <EmailSent svgwidth={30} svgheight={30} /> : <Upgrade svgwidth={30} svgheight={30} />}
            <br/>
            <div className="templatetitle">Custom Templates
            {
              !this.props.isCustomTemplateAllowed ?
                <CustomTemplateUnavailableComponent /> :
                (
                  this.state.emailTemplatesdata && this.state.emailTemplatesdata.length > 1 ?
                    this.state.emailTemplatesdata.length === 2 ?
                      <div>
                        <span className="descriptionoftem">{this.state.emailTemplatesdata[1].key}</span>
                      </div> :
                      <div>
                        <span className="descriptionoftem">Select one of your templates</span>
                        <DropdownComponent
                          className={this.getSelectedTemplate() === NO_TEMPLATE_SELECTED_LABEL ? 'input-required' : ''}
                          selected={this.getSelectedTemplate()}
                          data={this.state.emailTemplatesdata}
                          onChange={(item) => this.emailTemplateChange(item.selected)} />
                      </div> : <div><span className="descriptionoftem">No Templates</span></div>
                )
            }
            </div>
          </button>
          {this.state.isSuperUser &&
          <button onClick={(e) => this.props.onChange(EmailAttributes.emailTemplate, EmailTemplates.stripo)} 
            className={this.buttonClass([EmailTemplates.stripo], 'stripo')}>
              <EmailSent svgwidth={30} svgheight={30} /><br/>
              <div className="templatetitle">
                Stripo Template
                <div onClick={() => this.props.push(DashboardMenuOption.stripo)} className="descriptionoftem editStripoTemplateLink">Click here to edit your Stripo Template</div>
              </div>
              
          </button>}
      </div>
      </div> :
      <div className="section">
        <div className="small-title-description">You have a self owned customized template.</div>
        <div className="block template">
        <button className={this.buttonClass(['custom'], 'custom')}>
            <EmailSent svgwidth={30} svgheight={30} /><br/>
            <div className="templatetitle">{getAttribute(this.props.data, EmailAttributes.emailTemplate)}</div>
        </button>
        </div>
      </div>}
      </div>
  }
  private onCustomTemplateClick = () => {
    if (this.state.emailTemplatesdata.length === 2 && this.props.isCustomTemplateAllowed) {
      this.emailTemplateChange(this.state.emailTemplatesdata[1])
    } else if (!this.props.isCustomTemplateAllowed) {
      this.props.onChange(UpgradeTemplateField, UpgradeTemplateFieldVal.customTemplate)
    }
  }
  private getSelectedTemplate = () => {
    const currentTemplate = getAttribute(this.props.data, EmailAttributes.emailTemplate)
    return !isNil(currentTemplate) && currentTemplate.indexOf('custom') > -1 &&
      this.state.emailTemplatesdata.length > 0 ? this.state.emailTemplatesdata.filter((x) =>
      x.value === currentTemplate.split(':')[1])[0].key :
      this.state.emailTemplatesdata.length > 0 ? this.state.emailTemplatesdata[0].key : ''
  }
  private emailTemplateChange = (item) =>  {
    if (this.props.onChange) {
      this.props.onChange(EmailAttributes.emailTemplate, 'custom:' + item.value)
    }
    if ( item.value === NO_TEMPLATE_SELECTED ) {
      this.props.setSectionValid(this.props.name, false)
    } else {
      this.props.setSectionValid(this.props.name, true)
    }
  }
  private buttonClass = (templateNames: string[], prefix: string): string => {
    const selectedTemplate = getAttribute(this.props.data, EmailAttributes.emailTemplate)
    const isSelected: boolean = templateNames.includes(selectedTemplate)
      || (templateNames.includes('custom') && selectedTemplate && selectedTemplate.indexOf('custom') > -1)
    return `template ${prefix}-temp ${isSelected && 'selected'}`
  }
  private loadEmailTemplates = () => {
    return new Dataset().loadCommunityDataset('htmlTemplates', this.state.communityId)
    .then((htmlTemplates) => {
      const data = [{
        key: NO_TEMPLATE_SELECTED_LABEL,
        value: NO_TEMPLATE_SELECTED,
      },
      ...htmlTemplates[0].map((x) => {
        return {
          key: x.fileName,
          value: x.id,
        }
      })]
      this.setState({
        emailTemplatesdata: data,
        emailTemplates: htmlTemplates[0],
        selected: this.getSelectedTemplate(),
      })
    })
  }
}

export const TemplateEdit = connect(
  null,
  {
    push: Router.push,
  },
  )(TemplateEditComponent)
