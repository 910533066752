import classnames from 'classnames'
import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import * as Flash from 'components/flash'
import { GeneratedTextModalComponent } from 'components/generate-text'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import { AllComponentPropsWithModal, registerNewComponentWithModals } from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty, isNil, shuffle } from 'lodash'
import React from 'react'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Constants from 'shared/templates/constants'
import {
  DEFAULT_COMMUNITY_PARTNER_CODES,
  EmailTemplates,
  FormatTypes,
  LEAD_TEXT_TYPE_PREFIX,
  NORMAL_BOOSTED_BOOST_LEVEL,
  SUPER_BOOSTED_BOOST_LEVEL,
} from 'shared_server_client/constants'
import { formatDateTimeForLambda } from 'shared_server_client/dates'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { TemplateModule, TemplateModuleType } from 'shared_server_client/types/email_layout'
import { TAB_SELECTED } from 'store/constants'
import * as GA from '../../google-analytics';
import { loadSampleArticles } from '../../shared/utils';
import {
  Article,
  EMAIL_SENT_FAILURE,
  EMAIL_SENT_SUCCESSFULLY,
  EmailAttributes,
  EmailLayoutData,
  getAttribute,
  getHash,
  getMenuSection,
  Image,
  isValidTab,
  MenuIcons,
  MenuSections,
  modulesChanged,
  PreviewContent, PreviewMenuSections,
  Text,
  UpgradeTemplateField,
  UpgradeTemplateFieldVal,
} from './constants'
import { ColorEdit } from './edit-sections/colors'
import { CountsEdit } from './edit-sections/counts'
import { FooterEdit } from './edit-sections/footer'
import { HeaderLogoWidth } from './edit-sections/headerandlogo'
import { LeadTextEditor } from './edit-sections/leadTextEditor'
import { PopOutModalComponent } from './edit-sections/popOutModalComponent';
import { SectionEditor } from './edit-sections/sectionEditor'
import { SendFrom } from './edit-sections/sendfrom'
import { SendPreview } from './edit-sections/sendPreview'
import { TemplateLayout } from './edit-sections/templateLayout'
import { ModuleAction, ModulePropsChangeArgs } from './edit-sections/templateModule'
import { TemplateEdit } from './edit-sections/templates'
import { UploadImage } from './edit-sections/uploadImage'
import { BoldLayout } from './layouts/big-and-bold/component'
import { ClassicLayout } from './layouts/classic/component'
import { CustomNoPreviewLayout } from './layouts/custom/componentNoPreview'
import { InsightsLayout } from './layouts/insights/component'
import { NewspaperLayout } from './layouts/newspaper/component'
import './styles.scss'
import { getArticlePreviewError, getMatchingModules, getModuleIndex, resetTemplateModules, sendEmail } from './utils'
import {
  FeatureUnavailableComponent,
  FeatureUnavailableComponentName,
  FeatureUnavailableProps
} from '../feature-unavailable'
import { DESIGN_CUSTOMTEMPLATE_ADDITIONAL_FEATURES, DESIGN_LAYOUT_ADDITIONAL_FEATURES } from '../upgrade/constants'

interface EmailLayoutDataProps {
  code?: any
  setConfigData?: any
}
type EmailLayoutProps = AllComponentPropsWithModal<EmailLayoutData> & EmailLayoutDataProps

interface ArticlePool {
  articles?: Article[],
  boosted?: Article[],
  maxArticleCount?: number,
  maxEventCount?: number,
  maxLeadCount?: number,
  minArticleCount?: number,
  scheduled?: Article[],
  superBoosted?: Article[],
}

interface EmailLayoutState {
  articlePool?: ArticlePool,
  articlesLoading: boolean,
  communityId?: string,
  communitySchedule?: any,
  content: PreviewContent,
  context?: any,
  emailAddress?: string,
  isCustomTemplateAllowed?: boolean,
  previewDate?: Date,
  previewImages?: Image[],
  previewTexts?: Text[],
  isPreviewing: boolean,
  isSavingLayout: boolean,
  isUpgradeTemplatePreview: boolean;
  selected?: MenuSections,
  sendTo?: string,
  validSections: any,
  communityPartnerData?: object,
  isSuperUser: boolean,
  selectedCode?: any,
  changes: any,
  originalProps?: any,
  dirtySections?: any,
  wasPreviouslyDirty: boolean,
}

interface EditNavigationProps {
  icon: any,
  isLastElement: boolean,
  isValid: boolean,
  onClick: any,
  section: MenuSections,
  selected: boolean,
  disabled?: boolean,
  isDirty?: boolean
}

const enumAsList = (obj: any) =>
  Object.keys(obj).map((key: string) => obj[key])

const EditNavigation = ({ icon, section, onClick, selected, isLastElement, isValid, disabled, isDirty }:
  EditNavigationProps) => {
  const cssClassNames = [
    'design-menu',
    'clickable-item',
    'menu-item',
  ]
  const iconClassNames = [
    'pencil',
  ]
  if ( selected ) {
    cssClassNames.push('selected')
  }
  if ( isLastElement ) {
    cssClassNames.push('extra-margin')
  }
  if ( !isValid ) {
    cssClassNames.push('section-invalid')
  }
  if ( disabled ) {
    cssClassNames.push('rasa-noclick')
  }
  if ( isDirty ) {
    iconClassNames.push('fa fa-pencil')
  }
  return <div className={classnames(cssClassNames)}
    onClick={() => onClick(section)}>
    {icon}
    {section}
    <i className={classnames(iconClassNames)}></i>
  </div>
}

type SectionValidator = (key: string, isValid: boolean) => void
export interface EditSectionProps {
  data: any,
  name: MenuSections | PreviewMenuSections,
  onChange: any,
  select?: any,
  choose?: any,
  chooseLT2?: any,
  articles?: any,
  showRasaLogo?: boolean,
  setSectionValid?: SectionValidator,
  isDirty?: boolean,
  setSectionDirty?: any,
  sectionSelect?: any,
  saveLayout?: any
}

class EmailLayoutComponent extends RasaReactComponent<EmailLayoutProps, EmailLayoutState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private emailLayoutId: number = null
  constructor(props: EmailLayoutProps) {
    super(props, 'email_layout', {
      articlePool: {},
      articlesLoading: false,
      content: {
        articles: [],
        events: [],
      },
      previewImages: [],
      isPreviewing: false,
      isSavingLayout: false,
      isUpgradeTemplatePreview: false,
      validSections: {
      },
      selected: MenuSections.template,
      isSuperUser: false,
      changes: {},
      originalProps: {},
      dirtySections: {},
      wasPreviouslyDirty: false,
    })
    this.setSegmentCode = this.setSegmentCode.bind(this)
  }
  public componentWillReceiveProps(nextProps) {
    if (nextProps.code !== this.props.code) {
      this.setState({
        communityPartnerData: nextProps.code,
      })
    }
  }
  public componentWillUpdate = (nextProps, nextState) => {
    if (nextState.isDirty) {
      this.props.setLeavePageWarning({
        leaveWarningMessage: 'If you leave, you will lose your unsaved changes.  Continue?',
        skipUrls: this.getEmalLayoutUrlsList(),
      })
    } else {
      this.props.setLeavePageWarning({
        leaveWarningMessage: '',
      })
    }
  }
  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.hash),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.role),
    ])
      .then(([activeCommunity, hash, person, role]) => {
        const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
        if (activeCommunity.communityInfo && activeCommunity.communityInfo.data.schedule
          && activeCommunity.communityInfo.data.schedule.length > 0) {
          this.emailLayoutId = activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id
          this.loadRecord(activeCommunity.communityId, this.emailLayoutId)
            .then(() => {
              this.setState({
                communityId: activeCommunity.communityId,
                emailAddress: person.email,
                isCustomTemplateAllowed: avlFeatures.indexOf(BillingPlanDetailCode.EMAIL_TEMPLATES) > -1,
                selected: (hash && isValidTab(hash)) ? getMenuSection(hash) : this.state.selected,
                sendTo: person.email,
                communitySchedule: activeCommunity.communityInfo.data.schedule[0],
                isSuperUser: role === Roles.super_admin,
                //when select no-code from Segment Dropdown, needs to populate original values.
                originalProps: this.props.data,
              }, () => this.getArticlesData())
            })
        }
      })
  }

  public propertyChanged(field: string, value: any) {
    super.propertyChanged(field, value)
    if (field === UpgradeTemplateField) {
      if (value) {
        this.setState({
          isDirty: false,
        })
      } else {
        this.setState({
          isDirty: this.state.wasPreviouslyDirty,
        })
      }
    } else {
      super.propertyChanged(UpgradeTemplateField, false)
      this.setState({
        wasPreviouslyDirty: true,
      })
    }
    if (this.state.selected) {
      this.setState({
        dirtySections: {
          ...this.state.dirtySections,
          [this.state.selected]: {
            isDirty: true,
          },
        },
      })
    }
    if (field !== UpgradeTemplateField) {
      this.setState({
        changes: {
          ...this.state.changes,
          [field]: value,
        },
      }, () => {
        super.propertyChanged('changes', this.state.changes)
      })
    }
  }

  public render = () => {
    return (
      <div className="email-design">
        {this.state.articlesLoading ? <Loading size="64" /> :
          <div className="email-container">
            <div className="preview"
              style={{
                backgroundColor: getAttribute(this.props.data, EmailAttributes.emailBodyBackgroundColor),
                paddingTop: '0px',
              }}>
              {
                this.props.data[UpgradeTemplateField] ?
                  <FeatureUnavailableComponent {...this.featureUnavailableProps()} />
                  : <div className="email-body"
                         style={{ backgroundColor: '#FFFFFF', margin: '0px 100px' }}>
                    {this.getTemplate()}
                </div>
              }
            </div>
            <div className="settings">
            <div  className={classnames(this.segmentDropdownClassNames())}>
              <LoadSegmentCodes setSegmentCode={this.setSegmentCode} setConfigData={this.setConfigData}/>
            </div>
              <div className="designbuttonwrap">
                <div className={classnames(this.saveButtonClassNames())}
                     onClick={(e) => this.saveLayout(this.emailLayoutId)}>
                  {this.state.isSavingLayout ? <Loading size="32"/> : <span>Save</span>}
                </div>
              </div>
              <div className="editor">
                {this.getEditor()}
              </div>
            </div>
            <div className="menu">
              {this.isVisible(enumAsList(MenuSections)).filter((x) => {
                const selectedTemplate = getAttribute(this.props.data, EmailAttributes.emailTemplate)
                if ( selectedTemplate && (
                       selectedTemplate.indexOf('custom') > -1 ||
                       !Object.values(EmailTemplates).includes(selectedTemplate)
                    ) &&
                    (
                      x === MenuSections.fontAndColor ||
                      x === MenuSections.footer ||
                      x === MenuSections.layout
                    )
                ) {
                  return false
                }
                return true
              }).map((section: MenuSections, i: number) =>
                <EditNavigation key={section}
                  isLastElement={i === 7}
                  isValid={this.isSectionValid(section)}
                  section={section}
                  icon={MenuIcons[section] || ''}
                  isDirty={this.isSectionDirty(section)}
                  selected={section === this.state.selected}
                  onClick={this.selectItem}
                  disabled={this.setNavigationMenuDisabled(section)} />)}
            </div>
          </div>
        }
      </div>)
  }

  private featureUnavailableProps = () => {
    let componentToLoad: FeatureUnavailableProps = {
      loadComponent: FeatureUnavailableComponentName.AnchorLink,
      source: GA.UpgradeSource.CustomTemplate,
    }

    switch (this.props.data[UpgradeTemplateField]) {
      case UpgradeTemplateFieldVal.customTemplate:
        componentToLoad = {
          loadComponent: FeatureUnavailableComponentName.Upgrade,
          source: GA.UpgradeSource.CustomTemplate,
          upgradeComponentProps: {
            source: GA.UpgradeSource.CustomTemplate,
            features: DESIGN_CUSTOMTEMPLATE_ADDITIONAL_FEATURES,
            description: 'Customization and Features when you upgrade your plan',
            onCancel: () => {
              this.propertyChanged(UpgradeTemplateField, false)
            }
          },
        }
        break
      case UpgradeTemplateFieldVal.layout:
        componentToLoad = {
          loadComponent: FeatureUnavailableComponentName.Upgrade,
          source: GA.UpgradeSource.LayoutModules,
          upgradeComponentProps: {
            source: GA.UpgradeSource.LayoutModules,
            features: DESIGN_LAYOUT_ADDITIONAL_FEATURES,
            description: 'Customization and Features when you upgrade your plan',
            onCancel: () => {
              this.propertyChanged(UpgradeTemplateField, false)
            }
          },
        }
        break
      case UpgradeTemplateFieldVal.contactToRasa:
        componentToLoad = {
          loadComponent: FeatureUnavailableComponentName.ContactSupport,
          source: GA.UpgradeSource.CustomTemplate,
          contactToSupportProps: {
            headline: 'You’ve reached your limit.',
            onCancel: () => {
              this.propertyChanged(UpgradeTemplateField, false)
            }
          },
        }
        break
    }

    return componentToLoad
  }

  private isSectionDirty = (section: string): boolean => {
    const sectionState  = this.state.dirtySections[section]
    return sectionState && sectionState.isDirty
  }

  private clearChanges = () => {
    this.setState({
      changes: {},
      dirtySections: {},
      isSavingLayout: false,
    })
  }

  private setNavigationMenuDisabled = (section: any): boolean => {
    const allowedSections = [MenuSections.footer, MenuSections.fontAndColor, MenuSections.headerAndLogo, MenuSections.sendFrom]
    return (!this.state.selectedCode || this.state.selectedCode.key === DEFAULT_COMMUNITY_PARTNER_CODES.ALL)
      ? false
      : (this.state.selectedCode.key !== DEFAULT_COMMUNITY_PARTNER_CODES.ALL && !allowedSections.includes(section))
  }

  private saveButtonClassNames = (): string[] => {
    const cssClassNames = [
      'save-button',
    ]
    if ( this.state.isSavingLayout ) {
      cssClassNames.push('save-button-disabled')
    } else {
      if ( this.allSectionsValid() ) {
        if ( this.isDirty ) {
          cssClassNames.push('save-button-enabled')
          cssClassNames.push('clickable-item')
        } else {
          cssClassNames.push('save-button-disabled')
        }
      } else {
        cssClassNames.push('save-button-disabled')
        cssClassNames.push('invalid')
      }
    }
    return cssClassNames
  }

  private segmentDropdownClassNames = (): string[] => {
    const allowedSections = [MenuSections.footer, MenuSections.fontAndColor, MenuSections.sendFrom]
    const selectedTemplate = getAttribute(this.props.data, EmailAttributes.emailTemplate)
    if (selectedTemplate && selectedTemplate.indexOf('custom') === -1) {
      allowedSections.push(MenuSections.headerAndLogo)
    }
    const cssClassNames = [
      'editor',
    ]
    if ( this.isDirty || !allowedSections.includes(this.state.selected)) {
      cssClassNames.push('rasa-noclick')
    }
    return cssClassNames
  }

  private getEmalLayoutUrlsList = () => {
    return Object.keys(MenuSections).map((key: string) =>
      DashboardMenuOption.emailDesign + '?#' + key.toLowerCase())
  }

  private sendNewsletter = (email: string, date: Date = null) => {
    this.setState({
      isPreviewing: true,
    })
    const payload: any = {
      CommunityIdentifier: this.state.communityId,
      OverrideEmail: email,
      UseTemplateCache: false,
      sendModeDescription: Constants.DESIGN_MODE.description,
      LastCutoff: 28,
      minimum_newsbrief_count: 0,
    }
    if (date) {
      // FeatureDate has to be a community localized UTC datetime string
      payload.FeatureDate = formatDateTimeForLambda(date)
    }

    sendEmail(this.state.communityId, email, payload, date)
      .then((message) => {
        this.context.store.dispatch(Flash.showFlashMessage(EMAIL_SENT_SUCCESSFULLY))
        this.setState({
          isPreviewing: false,
        })
      })
      .catch((error) => {
        this.context.store.dispatch(Flash.showFlashError(EMAIL_SENT_FAILURE))
        this.setState({
          isPreviewing: false,
        })
      })
  }

  private selectItem = (item: MenuSections, context: any = {}) => {
    if (Object.keys(this.props.data || {}).length) {
      this.setState({
        context,
        selected: item,
      })
      this.context.store.dispatch({ type: TAB_SELECTED, hash: getHash(item) })
    }
  }

  private isVisible = (menuSections: any[]): any[] => {
    const hiddenSections = [MenuSections.uploadImage, MenuSections.leadTextEditor, MenuSections.leadText2Editor,
      MenuSections.sectionEditor]
    return menuSections.filter((s: MenuSections) => !hiddenSections.includes(s))
  }

  private getEditor = () => {
    const displayArticles = this.state.content ?
      this.state.content.articles : []
    const { context } = this.state
    if (this.state.selected) {
      switch (this.state.selected) {
        case MenuSections.template:
          return <TemplateEdit
            data={this.props.data}
            isCustomTemplateAllowed={this.state.isCustomTemplateAllowed}
            setSectionValid={this.setSectionValid}
            name={MenuSections.template}
            onChange={(field: string, value: string) =>
              this.propertyChanged(field, value)} setConfigData={this.setConfigData} />
        case MenuSections.fontAndColor:
          return <ColorEdit data={this.getPropsData()}
            setSectionValid={this.setSectionValid}
            name={MenuSections.fontAndColor}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)} />
        case MenuSections.articleSettings:
          return <CountsEdit data={this.props.data}
            name={MenuSections.articleSettings}
            setSectionValid={this.setSectionValid}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)} />
        case MenuSections.footer:
          return <FooterEdit data={this.getPropsData()}
            modals={this.props.modals}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            isDirty={this.isDirty}
            name={MenuSections.footer}
            setSectionValid={this.setSectionValid}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)} />
        case MenuSections.headerAndLogo:
          return <HeaderLogoWidth data={this.getPropsData()}
            name={MenuSections.headerAndLogo}
            setSectionValid={this.setSectionValid}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)} />
        case MenuSections.leadTextEditor:
          return <LeadTextEditor data={this.props.data}
            name={MenuSections.layout}
            modals={this.props.modals}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            context={context}
            push={this.props.push}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)}
            setSectionValid={this.setSectionValid}
            select={this.selectItem}
            staticTextType={`${LEAD_TEXT_TYPE_PREFIX}${context.sequence}`} />
        case MenuSections.sendFrom:
          return <SendFrom
            data={this.getPropsData()}
            modals={this.props.modals}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            isDirty={this.isDirty}
            setSectionValid={this.setSectionValid}
            articles={displayArticles}
            name={MenuSections.sendFrom}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)}
          />
        case MenuSections.layout:
          return <TemplateLayout data={this.props.data}
            name={MenuSections.layout}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)}
            select={(childContext: any) => this.selectItem(MenuSections.uploadImage, childContext)}
            sectionSelect={(childContext: any) => this.selectItem(MenuSections.sectionEditor, childContext)}
            choose={(childContext: any) => this.selectItem(MenuSections.leadTextEditor, childContext)} />
        case MenuSections.sectionEditor:
          return <SectionEditor data={this.props.data} {...this.props}
          name={MenuSections.layout}
          modals={this.props.modals}
          openModal={this.props.openModal}
          closeModal={this.props.closeModal}
          context={context}
          push={this.props.push}
          onChange={(field: string, value: string) => this.propertyChanged(field, value)}
          setSectionValid={this.setSectionValid}
          selectSection={this.selectItem}
          staticTextType={null}
          onModulePropsChange={this.handleArticlePropsChange}
          hidden={false}
          type={null}
          moduleConfig={null}
          sequence={null} />
        case MenuSections.uploadImage:
          return <UploadImage data={this.props.data}
            name={MenuSections.layout}
            openModal={this.props.openModal}
            modals={this.props.modals}
            closeModal={this.props.closeModal}
            context={context}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)}
            push={this.props.push}
            select={this.selectItem} />
        case MenuSections.sendPreview:
          return <SendPreview data={this.props.data}
            dirty={this.state.isDirty}
            sendNewsletter={this.sendNewsletter}
            sendTo={this.state.sendTo}
            changeSendTo={this.changeSendTo}
            name={MenuSections.sendPreview}
            articlePreviewError={displayArticles.length < this.props.data.minimum_newsbrief_count ?
              getArticlePreviewError(this.props.data.minimum_newsbrief_count) : ''}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)} />
      }
    }
    return <div>No Editor Found</div>
  }

  private changeSendTo = (value: string) => {
    return this.setState({ sendTo: value })
  }

  private handleArticlePropsChange = (change: ModulePropsChangeArgs) => {
    const newTemplateModules: TemplateModule[] = structuredClone(this.props.data.template_modules)
    if (change.action === ModuleAction.clone) {
      const oldModuleIndex = getModuleIndex(newTemplateModules, change.module.type, change.oldSequence)
      newTemplateModules.splice(oldModuleIndex + 1, 0, change.module)
    } else if (change.action === ModuleAction.delete) {
      const deleteModuleIndex = getModuleIndex(newTemplateModules, change.module.type, change.module.sequence)
      newTemplateModules.splice(deleteModuleIndex, 1)
    }

    const currentModuleIndex = getModuleIndex(newTemplateModules, change.module.type, change.module.sequence)
    const restOfArticlesModuleIndex = getModuleIndex(newTemplateModules, TemplateModuleType.restOfArticles, 1)

    const foundModules = getMatchingModules(newTemplateModules, change.module)
    let consumedCount: number = 0
    foundModules.forEach((foundModule) => {
      if (foundModule.index < currentModuleIndex) {
        consumedCount += foundModule.module.moduleConfig.count
      } else if (foundModule.index === currentModuleIndex) {
        // same module.
        change.module.moduleConfig.start = consumedCount
        change.module.moduleConfig.end = consumedCount + change.module.moduleConfig.count
        newTemplateModules[foundModule.index] = change.module
        consumedCount += change.module.moduleConfig.count
      } else {
        foundModule.module.moduleConfig.start = consumedCount
        foundModule.module.moduleConfig.end = consumedCount + foundModule.module.moduleConfig.count
        newTemplateModules[foundModule.index] = foundModule.module
        consumedCount += foundModule.module.moduleConfig.count
      }
    })

    // now reset the index for rest of articles
    const restOfArticlesModule = newTemplateModules[restOfArticlesModuleIndex]
    restOfArticlesModule.moduleConfig.start = consumedCount
    newTemplateModules[restOfArticlesModuleIndex] = restOfArticlesModule
    if ( modulesChanged(newTemplateModules, this.props.data.template_modules) ) {
      this.propertyChanged('template_modules', newTemplateModules)
    }
  }

  private saveLayout = (recordId: number) => {
    // Perform before save operations
    if ( this.isDirty && this.allSectionsValid() ) {
      resetTemplateModules(this.props.data.template_modules)
      this.setState({
        isSavingLayout: true
      }, () => {
        this.saveRecord(recordId).then(() => {
          this.updatePropsAfterSaving()
          const templateName = getAttribute(this.props.data, EmailAttributes.emailTemplate)
          if (getAttribute(this.state.changes, EmailAttributes.emailTemplate)
            || (templateName && templateName.startsWith('custom:'))) {
            window.location.reload()
          } else {
            this.clearChanges()
          }
        })
      })
    }
  }

  private getTemplate = () => {
    const content = this.state.content
    const images = this.state.previewImages
    const texts = this.state.previewTexts
    const templateName = getAttribute(this.props.data, EmailAttributes.emailTemplate)

    switch (templateName) {
      case EmailTemplates.newspaper:
      case EmailTemplates.oldNewspaper:
        return <NewspaperLayout session={this.getPropsData()}
                                content={content}
                                images={images}
                                isLoading={this.state.articlesLoading}
                                texts={texts}
                                previewDate={this.state.previewDate} />
      case EmailTemplates.default:
      case EmailTemplates.oldDefault:
        return <ClassicLayout session={this.getPropsData()}
                              content={content}
                              images={images}
                              isLoading={this.state.articlesLoading}
                              texts={texts}
                              previewDate={this.state.previewDate} />
      case EmailTemplates.bigAndBold:
      case EmailTemplates.oldBigAndBold:
        return <BoldLayout session={this.getPropsData()}
                           content={content}
                           images={images}
                           isLoading={this.state.articlesLoading}
                           texts={texts}
                           previewDate={this.state.previewDate} />
      case EmailTemplates.insights:
        return <InsightsLayout session={this.getPropsData()}
                               content={content}
                               images={images}
                               isLoading={this.state.articlesLoading}
                               texts={texts}
                               previewDate={this.state.previewDate} />
      default:
        if (templateName && templateName.startsWith('custom:')) {
          return <CustomNoPreviewLayout session={this.props.data}
                                        template={templateName}
                                        communityId={this.state.communityId}
                                        content={content}
                                        isLoading={this.state.articlesLoading}
                                        images={images}
                                        texts={texts}
                                        previewDate={this.state.previewDate} />
        } else {
          return <NewspaperLayout session={this.getPropsData()}
                                  content={content}
                                  isLoading={this.state.articlesLoading}
                                  images={images}
                                  texts={texts}
                                  previewDate={this.state.previewDate} />
        }
    }
  }

  private getArticlesData = () => {
    if (this.state.articlePool && this.state.articlePool.articles) {
      // Already retrieved articles
      return
    }
    this.setState({
      articlesLoading: true,
    })
    this.getArticleList().then((articleList) => {
      const articlesOnly = articleList.filter((e: any) => !e.scheduled_article_id && isNil(e.section_name)).length ?
        articleList.filter((e: any) => isNil(e.section_name)) : articleList
      const articlePool: ArticlePool = {
        articles: articleList.filter((e: any) => !e.scheduled_article_id &&
          (!e.boost_level || e.boost_level === 0 || !isEmpty(e.section_name))),
        boosted: articlesOnly.filter((e: any) => e.boost_level > 0 &&
          e.boost_level === NORMAL_BOOSTED_BOOST_LEVEL && !e.scheduled_article_id),
        maxArticleCount: Number(this.props.data.newsbrief_count) || 10,
        maxLeadCount: Number(this.props.data.max_lead_count) || Number(this.props.data.newsbrief_count) || 10,
        minArticleCount: Number(this.props.data.minimum_newsbrief_count) || 4,
        scheduled: [],
        superBoosted: articlesOnly.filter((e: any) => e.boost_level === SUPER_BOOSTED_BOOST_LEVEL).slice(0, 1),
      }
      const content = this.initializePreviewContent(articlePool)
      if (content.articles.length < articlePool.minArticleCount) {
        const numNeeded = articlePool.minArticleCount - content.articles.length
        loadSampleArticles().then((contentList) => {
          const extraAnimals = shuffle(contentList).slice(0, numNeeded)
          content.articles = content.articles.concat(extraAnimals)
          this.setState({
            articlePool,
            articlesLoading: false,
            content,
          })
        });
      } else {
        this.setState({
          articlePool,
          articlesLoading: false,
          content,
        })
      }
    })
  }

  private getArticleList = () => {
    const params = [{
      param: 'isActive',
      value: 1,
    }]
    return new Dataset().loadCommunityDataset('communityUpcomingArticles', this.state.communityId, params)
      .then((r1) => {
        const articleList = r1[0].filter((e: any) => e.is_active)
        if (articleList.length === 0) {
          return new Dataset().loadCommunityDataset('communityArticleHistory', this.state.communityId, null, 50)
            .then((r2) => r2[0])
        } else {
          return articleList
        }
      })
  }

  private initializePreviewContent = (pool: ArticlePool): PreviewContent => {
    const sectionTypes = (pool.articles || []).filter((a: Article) => a.section_name)
    const articlesBySection = sectionTypes.reduce((result, a: Article) => {
      if (!result[a.section_name]) {
        return {
          ...result,
          [a.section_name]: [a],
        }
      } else {
        result[a.section_name] = result[a.section_name].concat(a)
        return result
      }
    }, {})
    return {
      articles: this.initializePreviewArticles(pool),
      events: this.initializePreviewEvents(pool),
      sections: articlesBySection,
    }

  }

  private initializePreviewArticles = (pool: ArticlePool): Article[] => {
    const boosted = (pool.superBoosted || []).concat(pool.boosted || []).slice(0, pool.maxLeadCount)
    const generalArticles = (pool.articles || []).filter((a: Article) => !a.section_name && a.format_type_id !== FormatTypes.event)
    const scheduled = pool.scheduled || []
    return scheduled.concat(boosted.concat(shuffle(generalArticles))).slice(0, pool.maxArticleCount)
  }

  private initializePreviewEvents = (pool: ArticlePool): Article[] => {
    const generalArticles = (pool.articles || []).filter((a: Article) => a.format_type_id === FormatTypes.event)
    return shuffle(generalArticles).slice(0, pool.maxEventCount)
  }

  private setSectionValid = (key: string, valid: boolean) => {
    this.setState({ isDirty: valid })
    this.setState({
      validSections: {
        ...this.state.validSections,
        [key]: {
          ...this.state[key],
          isValid: valid,
        },
      },
    })
  }

  private isSectionValid = (section: string): boolean => {
    const sectionState  = this.state.validSections[section]
    return !sectionState || sectionState.isValid
  }

  private allSectionsValid = (): boolean => {
    return Object.keys(this.state.validSections).reduce(
      ((result, key) => result && this.state.validSections[key].isValid), true)
      && (!(
        this.state.changes
        && UpgradeTemplateField in this.state.changes
        && this.state.changes.isUpgradeTemplatePreview === true
      ))
  }

  private setConfigData = (data: any) => {
    if (data && data.length > 0) {
      const objConfig = data.reduce((acc, curr) => ({...acc, [curr.key]: curr.value}), {})
      if (objConfig.template_modules) {
        delete objConfig.template_modules
      }
      this.setState({
        communityPartnerData: objConfig,
      }, () => {
        ////Updating PROPS directly. when updating props directly on selection of code,
        ////it changes/reflects in the UI correctly.
        ////But some props still remain from previous selections. Remove old entries from PROPS using original porps
        this.updateExistingProps(this.props.data)
        ////Update PROPS by fetched details
        const entries = Object.entries(objConfig)
        entries.forEach(([key, value]) => {
          this.props.propertyChanged(key, value)
        });
      })
    } else {
      //remove patrner_code changes from props and replace with original props details
      if (this.state.communityPartnerData) {
        this.updateExistingProps(this.state.communityPartnerData)
        this.setState({
          communityPartnerData: {},
        })
      } else {
        this.updateExistingProps(this.props.data)
      }
    }
  }

  private updateExistingProps = (data: any) => {
    const entries = Object.entries(data)
    entries.forEach(([key, value]) => {
      if (key !== 'community_partner_code') {
        if (this.state.originalProps[key]) {
          this.props.propertyChanged(key, this.state.originalProps[key])
        } else if (key !== 'header_date_format') {
          this.props.propertyChanged(key, '')
        }
      }
    });
  }

  private updatePropsAfterSaving = () => {
    if(this.state.selectedCode) {
      window.location.reload()
    }
  }

  private setSegmentCode = (code: any) => {
    if (code) {
      this.setState({
        selectedCode: code,
      }, () => {
        this.setState({selectedCode: code})
      })
      this.props.propertyChanged('community_partner_code', code.value ? code.value : '')
    }
  }

  private getPropsData = (): object => {
    if (this.state.selectedCode && this.state.selectedCode.value) {
      return {...this.props.data}
    } else {
      return {...this.props.data, community_partner_code: null}
    }
  }
}

export const EmailLayouts = registerNewComponentWithModals<EmailLayoutData>(
  EmailLayoutComponent,
  'email_layout',
  [
    GeneratedTextModalComponent.key,
    PopOutModalComponent.key,
  ],
  {})
