import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import React from 'react'
import * as BaseLayout from '../base_layout'
import './styles.scss'
import { AjaxWrapper, HttpMethod } from '../../../../generic/ajaxWrapper'
import * as Flash from '../../../flash'
import capitalize from 'lodash/capitalize'
import pluralize from 'pluralize'
import { FrameLandscapeIcon } from '../../../icons/frame-landscape'
import { Article } from '../../../icons/article'
import { TextIcon } from '../../../icons/text-icon'
import { EmailLayoutProps } from '../base_layout'

interface State {
  communityId: string
  htmlContent: string
  isLoadingPreview: boolean
}

interface CustomHtmlLayout {
  module: string
  priority: string
  quantity: string
  type: string
  rank: string
}

export class CustomNoPreviewLayout extends BaseLayout.BaseLayoutComponent<State> {
  public static contextType = RasaContext
  protected templateClassName: string = 'custom-nopreview-layout'

  constructor(props: BaseLayout.EmailLayoutProps) {
    super(props)
    this.state = {
      communityId: null,
      htmlContent: '',
      isLoadingPreview: true,
    }
  }

  public componentDidMount() {
    super.componentDidMount()

    if (this.props.template && this.props.communityId) {
      this.loadCustomTemplateLayout()
    } else {
      this.setState({
        isLoadingPreview: false,
      })
    }
  }

  public componentDidUpdate(oldProps: EmailLayoutProps) {
    if (
      oldProps.communityId !== this.props.communityId ||
      oldProps.template !== this.props.template
    ) {
      this.loadCustomTemplateLayout()
    }
  }

  private loadCustomTemplateLayout = () => {
    this.setState({
      isLoadingPreview: true,
      htmlContent: ''
    })

    this.context.user.init()
      .then(() => {
        const templateId = this.props.template.split(':')[1]
        const url: string = `${AjaxWrapper.getServerUrl()}/email/layout/${this.props.communityId}/${templateId}`

        AjaxWrapper.ajax(url, HttpMethod.GET, {})
          .then(
            (response: CustomHtmlLayout[]) => {
              const html = (
                <ul>
                  {response.map((el, i) => (
                    <li key={i}>
                      {['header', 'footer', 'image'].some(
                        (x) => x === el.module
                      ) && <FrameLandscapeIcon />}
                      {'article' === el.module && (
                        <Article svgwidth={50} svgheight={50} />
                      )}
                      {'html' === el.module && <TextIcon />}
                      {this.renderElementOutput(el)}
                    </li>
                  ))}
                </ul>
              )

              this.setState({
                htmlContent: html,
              })
            })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log('Error Response', err)
            this.context.store.dispatch(
              Flash.showFlashError(
                "Oops! There was a problem while getting template. Please try again."
              )
            )
          })
          .finally(() => {
            this.setState({
              isLoadingPreview: false,
            })
          })
      })
  }

  public render() {
    if (this.props.isLoading || this.state.isLoadingPreview) {
      return <Loading size="64" />
    } else {
      return this.state.htmlContent ? (
        <div className="customEmail-template-comp">
          {this.state.htmlContent}
        </div>
      ) : (
        <div className={this.templateClassName}>
          <div className="mode-banner-container">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  "Go to the <a href='/preview'>Preview</a> page to view your custom HTML template",
              }}
            ></div>
          </div>
        </div>
      )
    }
  }

  private renderElementOutput(el: CustomHtmlLayout): JSX.Element | string {
    const moduleType = el.module
    let title: JSX.Element | string = ''
    switch (moduleType) {
      case 'html':
        const html_rank = el.type ? el.type.charAt(el.type.length - 1) : '1'
        title = 'Text / Html ' + html_rank
        break
      case 'article':
        const section = this.props.session.sections
          .find((s) => s.category_type_name === el.type)
        if (section) {
          title = <div>
            {`Section: ${section.name}`} <br />
            {`${pluralize(capitalize(moduleType), el.quantity || 1, true)}`}
          </div>
        } else {
          title = el.priority
            ? `${capitalize(el.priority)} ${el.rank || 1}`
            : `${pluralize(capitalize(moduleType), el.quantity || 1, true)}`
        }
        break
      case 'image':
        const rank = el.type ? el.type.charAt(el.type.length - 1) : '1'
        const type = el.type ? el.type.replace(rank, '') : 'Banner'
        title = `${capitalize(type)} ${rank || 1}`
        break
      default:
        title = capitalize(moduleType)
    }
    return title
  }
}
