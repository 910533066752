import React from 'react';
import * as Modals from '../../../shared/modals';
import {leadTextAttributes} from '../../../shared_server_client/constants';
import {WysiwygEditor} from '../../wysiwyg/component';
import './_styles.scss';
interface PopOutModalState extends Modals.ModalComponentState {
  displayHtmlTab: boolean,
}

interface PopOutModalProps extends Modals.ModalComponentProps {
  html: string,
}

const emptyState: PopOutModalState = {
  displayHtmlTab: false,
}

export class PopOutModalComponent extends Modals.GenericModalComponent<PopOutModalProps, PopOutModalState> {
  public static key: string = 'PopOutModalComponent'
  public static defaultProps = {
    closeButtonText: 'Cancel',
    saveText: 'Ok',
    secondAction: Modals.ModalActionType.BUTTON,
  }

  private popOutHtml: string = ''

  constructor(props: PopOutModalProps) {
    super(props, PopOutModalComponent.key)
    this.state = emptyState
  }

  protected save(data: any) {
    this.props.saveModal(this.key, {
      ...data,
      [leadTextAttributes.text]: this.popOutHtml,
    })
  }

  protected renderChildren(data: any) {
    return (
      <WysiwygEditor
        onTabChange={(tab: string) => this.setState({displayHtmlTab: false})}
        displayHtmlTab={false}
        htmlEdited={false}
        html={this.props.html}
        height="60vh"
        onChange={(html: string, isHtmlEdited: boolean) => {
          this.popOutHtml = html
        }}/>
    )
  }
}
