import { Slider, SliderLabel } from '@progress/kendo-react-inputs'
import { sizedUrl} from 'app/utils'
import { DropdownComponent } from 'components/dropdown/component'
import { Upload } from 'components/icons/upload2'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { isTruthy } from 'generic/utility'
import * as React from 'react'
import Dropzone from 'react-dropzone'
import { Input } from 'reactstrap'
import { IMAGE } from 'shared_server_client/constants'
import { ToggleOff } from '../../../components/icons/toggleoff'
import { ToggleOn } from '../../../components/icons/toggleon'
import { EditSectionProps } from '../components'
import { DATE_FORMAT_OPTIONS, HEADER_LOGO_MAX_WIDTH, VIEW_IN_BROWSER_OPTIONS } from '../constants'
import {EmailAttributes,  getAttribute, logoSizeAsNumber } from '../constants'

export class HeaderLogoWidth extends React.Component<EditSectionProps> {
  public static contextType = RasaContext
  private communityId: string = null

  constructor(props: EditSectionProps) {
    super(props)
  }

  public componentDidMount() {
    this.context.user.init().then(({activeCommunity}) => {
      this.communityId = activeCommunity.communityId
    })
  }

  public render() {
    const isShowSubscribeUrlInHeader: boolean =
      isTruthy(getAttribute(this.props.data, EmailAttributes.showSubscribeUrlInHeader))
    const isShowUnsubscribeUrlInHeader: boolean =
      isTruthy(getAttribute(this.props.data, EmailAttributes.showUnsubscribeUrlInHeader))
    const selectedTemplate = getAttribute(this.props.data, EmailAttributes.emailTemplate)
    return <div className="logo-size">
      <span className="title">Header</span>
      {selectedTemplate.indexOf('custom') === -1 &&
      <div>
        <div className="section">
          <div className="block">
            <div className="toggle-active" onClick={() =>
              this.setAttribute(EmailAttributes.showSubscribeUrlInHeader, !isShowSubscribeUrlInHeader)}>
              <button className="toggle-button"
                      style={{background: 'none', border: 'none', outline: 'none'}}>
                {isShowSubscribeUrlInHeader ? <ToggleOn/> : <ToggleOff/> }
              </button>
              <span className="small-description">Include Subscribe Link</span>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="block">
            <div className="toggle-active" onClick={() =>
              this.setAttribute(EmailAttributes.showUnsubscribeUrlInHeader, !isShowUnsubscribeUrlInHeader)}>
              <button className="toggle-button"
                      style={{background: 'none', border: 'none', outline: 'none'}}>
                {isShowUnsubscribeUrlInHeader ? <ToggleOn/> : <ToggleOff/> }
              </button>
              <span className="small-description">Include Unsubscribe Link</span>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="block">
            <div className="small-title">Date Display</div>
            <div className="dropdownwrap">
              <DropdownComponent data={DATE_FORMAT_OPTIONS}
                selected={getAttribute(this.props.data, EmailAttributes.headerDateFormat)}
                onChange={(e: any) => {
                  this.props.onChange(EmailAttributes.headerDateFormat, e.selected.value)
                }}/>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="block">
          <div className="small-title">Logo size</div>
            <div className="description">
              Adjust the size of your logo or your company name
            </div>
            <div className="sliderwrap">
            <Slider
                  buttons={true}
                  step={25}
                  defaultValue={logoSizeAsNumber(this.props.data)}
                  min={50}
                  max={300}
                  onChange={(e) => {
                    this.props.onChange(EmailAttributes.logoSize, `${e.value}px`)
                  }}>
              <SliderLabel position={50}>50px</SliderLabel>
              <SliderLabel position={75}></SliderLabel>
              <SliderLabel position={100}>100px</SliderLabel>
              <SliderLabel position={125}></SliderLabel>
              <SliderLabel position={150}>150px</SliderLabel>
              <SliderLabel position={175}></SliderLabel>
              <SliderLabel position={200}>200px</SliderLabel>
              <SliderLabel position={225}></SliderLabel>
              <SliderLabel position={250}>250px</SliderLabel>
              <SliderLabel position={275}></SliderLabel>
              <SliderLabel position={300}>300px</SliderLabel>
            </Slider>
            </div>
          </div>
          <div className="logo-uploader">
            <div className="small-title">
              Upload logo
            </div>
            <div className="description">
              Use a high quality image. Only JPG or PNG allowed.
            </div>
            <div className="header-image-container">
              <Dropzone onDrop={(acceptedFiles) => this.uploadImage(acceptedFiles[0])}
                accept="image/*">
                {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()} className="lined-box clickable-item">
                        <input {...getInputProps()} />
                        <div className="image-or-placeholder-wrapper">
                          {getAttribute(this.props.data, EmailAttributes.brandHeaderImageUrl) ?
                          <img src={sizedUrl(getAttribute(
                              this.props.data, EmailAttributes.brandHeaderImageUrl), 150, 300, false)}/> : null}
                          {!getAttribute(this.props.data, EmailAttributes.brandHeaderImageUrl) ?
                          <div className="placeholder-wrapper">
                            <div className="upload-wrapper">
                              <div className="upload"><Upload/></div>
                            </div>
                            <p className="row1-text">Choose your file</p>
                            <p className="row2-text">You may drag and drop your file as well.</p>
                            <p className="row3-text">Upload only PNG, JPG.</p>
                          </div> : null}
                        </div>
                      </div>
                    </section>
                )}
              </Dropzone>
            </div>
          </div>
          <div className="block"></div>
          <br/>
          <div className="show-header">
            <div className="checkbox">
              <input type="checkbox" className="checkbox"
                checked={isTruthy(this.props.data[EmailAttributes.hideHeaderBackground])}
                onChange={(e) => this.setAttribute(EmailAttributes.hideHeaderBackground, e.target.checked)}
                id="show-header-checkbox"
              />
              <label htmlFor="show-header-checkbox">Transparent Header Background</label>
            </div>
          </div>
          <div className="block"></div>
          <br/>
          <div className="bottom-wrapper">
            <div className="small-title">
              Link to
            </div>
            <div className="description">
              Enter the URL you want this image to link to.
            </div>
            <div className="url-rectangle-wrapper">
              <div className="url-left-element">
                <div className="url-left-element-text">
                  URL
                </div>
              </div>
              <div className="url-right-element">
                <Input value={getAttribute(this.props.data, EmailAttributes.brandHeaderUrl) || ''}
                       onChange={(e) =>
                          this.props.onChange(EmailAttributes.brandHeaderUrl, e.target.value)}/>
                <br></br>
              </div>
            </div>
          </div>
          <br/>
          <div className="bottom-wrapper">
            <div className="small-title">
              Alt Text
            </div>
            <div className="url-rectangle-wrapper">
              <div className="url-right-element">
                <Input value={getAttribute(this.props.data, EmailAttributes.brandHeaderAltText) || ''}
                       onChange={(e) =>
                           this.props.onChange(EmailAttributes.brandHeaderAltText, e.target.value)}/>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>}
      <div className="section">
        <div className="block"></div>
        <div className="block">
          <div className="small-title">View in Browser</div>
          <div className="dropdownwrap">
            <DropdownComponent data={VIEW_IN_BROWSER_OPTIONS}
              selected={getAttribute(this.props.data, EmailAttributes.viewInBrowser)}
              onChange={(e: any) => {
                this.props.onChange(EmailAttributes.viewInBrowser, e.selected.value)
              }}/>
          </div>
        </div>
        <div className="last-item-margin"></div>
      </div>
    </div>
  }

  private uploadImage(image: any) {
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage) => {
        const aspectRatio = hostedImage.width / hostedImage.height
        const newWidth = hostedImage.width < HEADER_LOGO_MAX_WIDTH ? hostedImage.width : HEADER_LOGO_MAX_WIDTH
        const newHeight = newWidth / aspectRatio
        this.props.onChange(EmailAttributes.logoSize, `${newHeight}px`)
        this.props.onChange(EmailAttributes.brandHeaderImageUrl, hostedImage.url)
      })
      .catch((error) => {
        this.setState({
          error: true,
          loading: false,
        })
      })
  }

  private setAttribute = (attribute: EmailAttributes, newValue: any) => {
    // Placeholder - in case we need to do cross-validation of other numbers
    this.props.onChange(attribute, newValue)
  }
}
