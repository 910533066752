import { sizedArticleUrl } from 'app/utils'
import { getModuleConfigValue } from 'components/email-layout/utils'
import { Fade } from 'components/fade'
import { isTruthy } from 'generic/utility'
import React from 'react'
import { strfFormatDate } from 'shared_server_client/dates'
import { SectionConfig, TemplateModule, TemplateModuleType } from 'shared_server_client/types/email_layout'
import {
  Article,
  EmailAttributes, generalArticleAltText, headerAltText,
  headerLogoUrl,
  logoSizeAsNumber,
  ViewInBrowserFilter,
} from '../../constants'
import * as BaseLayout from '../base_layout'
import './styles.scss'

const defaultArticles = [{}, {}, {}, {}, {}, {}]

export class InsightsLayout extends BaseLayout.BaseLayoutComponent {
  protected templateClassName: string = 'insights-layout'
  protected isWhiteFooter = (): boolean => true

  protected headerModule = () => {
    const { previewDate } = this.props
    const displayDate = !previewDate ? new Date() : previewDate
    return <div className="template-header">
      {this.getAttribute(EmailAttributes.viewInBrowser) &&
          this.getAttribute(EmailAttributes.viewInBrowser) === ViewInBrowserFilter.TOP &&
            this.renderViewInBrowserAtTop(EmailAttributes.primaryColor)
      }
      <div className="date-and-subscribe text-uppercase">
        <div className="date">
          <p style={{color: this.getAttribute(EmailAttributes.primaryColor)}}>
          {strfFormatDate(displayDate, this.getAttribute(EmailAttributes.headerDateFormat))}</p>
        </div>
        <div className="subscription">
          {(this.getAttribute(EmailAttributes.subscribeUrl) &&
            isTruthy(this.getAttribute(EmailAttributes.showSubscribeUrlInHeader))) &&
            <a href="#">
              <p style={{color: this.getAttribute(EmailAttributes.primaryColor)}}>
                {this.getAttribute(EmailAttributes.subscribeUrlText)}
              </p>
            </a>}
          {(this.getAttribute(EmailAttributes.subscribeUrl) &&
            isTruthy(this.getAttribute(EmailAttributes.showUnsubscribeUrlInHeader))) &&
            <a href="#">
              <p className="unsubscribe-link" style={{color: this.getAttribute(EmailAttributes.primaryColor)}}>
                  {this.getAttribute(EmailAttributes.unsubscribeUrlText)}
              </p>
            </a>}
        </div>
      </div>
      {this.getAttribute(EmailAttributes.brandHeaderImageUrl) &&
      <div className="template-header-image-wrapper-container-insights">
        <div className="template-header-image-wrapper">
          <a href={this.props.session.brand_header_url}
            target="_blank"
            rel="noopener">
            <img className="template-header-image"
                  src={headerLogoUrl(this.props.session)}
                  alt={headerAltText(this.props.session)}
                  style={{height: `${logoSizeAsNumber(this.props.session)}px`}}/>
          </a>
        </div>
      </div>}
      {!this.getAttribute(EmailAttributes.brandHeaderImageUrl) &&
      <div className="template-header-text-wrapper-container">
        <div className="template-header-text-wrapper"
            style={{width: 'auto', height: 'auto'}}>
          <div className="template-header-text"
            style={{fontSize: this.getFontSize()}}>
            {this.getAttribute(EmailAttributes.companyName)}
          </div>
        </div>
      </div>}
    </div>
  }

  protected sectionModule = (module: TemplateModule) => {
    const articles = this.getSectionArticles(module)
    const section = this.getSectionDetails(module)
    //section will not be empty
    if ((articles && articles.length > 0) || section) {
      return <div className="article-wrapper" style={this.sectionStyles()}>
        {isTruthy(getModuleConfigValue(module, SectionConfig.SECTION_SHOW_HEADER, 'true')) &&
        <div className="section-title-wrapper">
          <span className="section-title" style={this.sectionTitleStyles(module)}>
            {section ?
             section.name : ''
            }
          </span>
        </div>}
        {articles && articles.length > 0  ?
          <div className="article-module">
            <div>
              {articles.slice(module.moduleConfig.start, module.moduleConfig.end || -1)
                      .map((article, i) => (
                <ArticleHtml
                  key={i}
                  showImage={this.showImage}
                  cappedDescription={this.cappedDescription}
                  buildStyle={this.buildStyle}
                  readMore={module.moduleConfig.readMore || ''}
                  {...this.props}
                  {...article}
                />
              ))}
            </div>
          </div>
          :
          <div className="no-article-section-module">
            There are no articles currently available for this section
          </div>
        }
      </div>
      } else {
        return null
      }
  }

  protected sectionStyles = () => {
    return {
      border: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
      padding: '15px',
    }
  }

  protected articleModule = (module: TemplateModule) => {
    if (!this.props.isLoading && this.props.content.articles.length === 0) {
      return <span className="no-content-message">You need more content for your next newsletter!</span>
    } else {
      return this.renderArticles(this.props.content.articles || defaultArticles, module)
    }
  }

  protected renderArticles = (articles: Article[], module: TemplateModule) => {
    if (articles && articles.length) {
      return <div className="article-module">
        <div style={this.articleStyles(module)}>
          {articles.slice(module.moduleConfig.start, module.moduleConfig.end || -1)
                   .map((article, i) => (
            <ArticleHtml key={i} showImage={this.showImage} cappedDescription={this.cappedDescription}
                         buildStyle={this.buildStyle} {...this.props} {...article} />
          ))}
        </div>
      </div>
    } else {
      return null
    }
  }

  protected eventModule = (module: TemplateModule) => {
    if (!this.props.isLoading && this.props.content.events.length === 0) {
      return <span className="no-content-message">You need more events for your next newsletter!</span>
    } else {
      return this.renderEvents(this.props.content.events || defaultArticles, module)
    }
  }

  protected renderEvents = (events: Article[], module: TemplateModule) => {
    if (events && events.length) {
      return <div className="article-module">
        <div style={this.articleStyles(module)}>
          {events.slice(module.moduleConfig.start, module.moduleConfig.end || -1)
                   .map((event, i) => (
            <EventHtml key={i} showImage={this.showImage} cappedDescription={this.cappedDescription}
                         buildStyle={this.buildStyle} {...this.props} {...event} />
          ))}
        </div>
      </div>
    } else {
      return null
    }
  }

  protected restOfArticlesModule = (module: TemplateModule) => {
    const restOfArticles: any[] = (this.props.content.articles || defaultArticles).slice(module.moduleConfig.start)
    if (restOfArticles.length) {
      return <div className="rest-of-articles-module">
        <div style={this.restOfArticlesStyles(module)}>
          {restOfArticles.map((article, i) =>
            <ArticleHtml key={i} showImage={this.showImage} cappedDescription={this.cappedDescription}
            buildStyle={this.buildStyle} {...this.props} {...article} />,
          )}
        </div>
      </div>
    } else {
      return null
    }
  }

  private buildStyle = (key: string) => {
    return {
      fontFamily: this.getAttribute(`${EmailAttributes.newsletterFontFamily}`) || 'Arial',
      fontSize: this.getAttribute(`${key}_${EmailAttributes.fontSize}`),
      fontWeight: this.getAttribute(`${key}_${EmailAttributes.fontWeight}`),
      color:  this.getAttribute(`${key}_${EmailAttributes.fontColor}`),
    }
  }

  private articleStyles = (module: TemplateModule): any => {
    if (this.nextModule(module)) {
      if (this.nextModule(module).type === TemplateModuleType.restOfArticles &&
          (this.props.content.articles || defaultArticles).length > module.moduleConfig.end) {
        return {
          borderBottom: '0px',
          borderLeft: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
          borderRight: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
          borderTop: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
          padding: '15px 15px 0px 15px',
        }
      } else if (this.nextModule(module).type === TemplateModuleType.article) {
        return {
          border: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
          padding: '15px',
          marginBottom: '15px',
        }
      } else {
        return {
          border: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
          padding: '15px',
        }
      }
    } else {
      // I am last module in the template - unlikely to be here - footer should be the last in general
      return {
        border: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
        padding: '15px',
      }
    }
  }

  private restOfArticlesStyles = (module: TemplateModule): any => {
    return this.previousModule(module) && this.previousModule(module).type === TemplateModuleType.article ? {
      borderBottom: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
      borderLeft: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
      borderRight: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
      borderTop: '0px',
      padding: '15px 15px 0px 15px',
    } : {
      border: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
      padding: '15px',
    }
  }
}

const ArticleHtml = (props: any) => <Fade>
  <div className="article">
    <div className="article-text-col">
      <div>
        <div className="source-title" style={props.buildStyle('source')}>
          <a className="article-title"
            id="source"
            target="_blank"
            rel="noopener"
            style={props.buildStyle('source')}
            href={props.url}>
              {props.site_name || props.source_name}
          </a>
        </div>
      </div>
      <div>
        <div>
          <a className="article-title"
             target="_blank"
             rel="noopener"
             style={props.buildStyle('title')}
             href={props.url}>
               {props.title}
          </a>
        </div>
      </div>
      <div>
        <div className="article-content" style={props.buildStyle('description')}>
          <span dangerouslySetInnerHTML={{__html: props.cappedDescription(props.description)}}></span>
        </div>
      </div>
      <div>
        <div className="read-more">
          <a style={props.buildStyle('description')}
             className="read-more-link"
             href={props.url}
             target="_blank"
             rel="noopener">
            <strong>{props.readMore || props.session[EmailAttributes.readMoreText]}</strong>
          </a>
        </div>
      </div>
    </div>
    {props.showImage(props, 200, 200) &&
    <div className="article-image">
      <a href={props.url} target="_blank" rel="noopener">
        <img src={sizedArticleUrl(props, 200, 200)}
             alt={generalArticleAltText(props.session)} height="200" width="200"/>
      </a>
    </div>}
  </div>
</Fade>

const EventHtml = (props: any) => <Fade>
  <div className="article">
    <div className="article-text-col">
      <div>
        <div className="source-title" style={props.buildStyle('source')}>
          <a className="article-title"
            id="source"
            target="_blank"
            rel="noopener"
            style={props.buildStyle('source')}
            href={props.url}>
              {props.site_name || props.source_name}
          </a>
        </div>
      </div>
      <div>
        <div>
          <a className="article-title"
             target="_blank"
             rel="noopener"
             style={props.buildStyle('title')}
             href={props.url}>
               {props.event_date}
          </a>
        </div>
      </div>
      <div>
        <div>
          <a className="article-title"
             target="_blank"
             rel="noopener"
             style={props.buildStyle('title')}
             href={props.url}>
               {props.title}
          </a>
        </div>
      </div>
      <div>
        <div className="article-content" style={props.buildStyle('description')}>
          <span dangerouslySetInnerHTML={{__html: props.cappedDescription(props.description)}}></span>
        </div>
      </div>
      <div>
        <div className="read-more">
          <a style={props.buildStyle('description')}
             className="read-more-link"
             href={props.url}
             target="_blank"
             rel="noopener">
            <strong>{props.readMore || props.session[EmailAttributes.readMoreText]}</strong>
          </a>
        </div>
      </div>
    </div>
    {props.showImage(props, 200, 200) &&
    <div className="article-image">
      <a href={props.url} target="_blank" rel="noopener">
        <img src={sizedArticleUrl(props, 200, 200)}
             alt={generalArticleAltText(props.session)} height="200" width="200"/>
      </a>
    </div>}
  </div>
</Fade>
