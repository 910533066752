import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import * as Flash from 'components/flash'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { emailSeparator } from 'generic/utility'
import { isEmpty } from 'lodash'
import React from 'react'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { PREVIEW_MODE } from 'shared/templates/constants'
import { formatDateTimeForLambda } from 'shared_server_client/dates'
import {
  ACTIVE_COMMUNITY_NOT_FOUND_ERROR,
  EMAIL_SENT_FAILURE,
  EMAIL_SENT_SUCCESSFULLY,
  EmailAttributes,
  MenuSections,
  RETRY_LIMIT,
  RETRY_WAIT_TIME
} from './constants'
import { SendPreview } from './edit-sections/sendPreview'
import * as Utils from './utils'

interface IEmailPreview {
  previewDate?: Date,
  segmentCode?: string,
}
interface EmailPreviewState {
  articlePreviewError: string,
  communityId: string,
  emailLayoutId: number,
  emailTemplateId: string,
  htmlPreview: string,
  sendTo: string,
  community_partner?: any,
  communityRecordId?: any,
  isHtmlPreviewLoading: boolean,
  segmentCode?: any,
  configData?: any,
  isSuperUser: boolean,
  previewDate: Date,
  retryCount: number,
}
type EmailPreviewComponentProps = GenericRedux.AllComponentProps<any>
export class EmailPreviewComponent extends RasaReactComponent<EmailPreviewComponentProps, EmailPreviewState> {
  public static contextType = RasaContext
  private emailLayoutId: number = null
  private sharedStore: SharedStore
  constructor(props: any) {
    super(props, 'email_layout')
    this.state = {
      articlePreviewError: '',
      communityId: '',
      emailLayoutId: 0,
      emailTemplateId: '',
      htmlPreview: '',
      isDirty: false,
      isSaving: false,
      isHtmlPreviewLoading: true,
      isLoading: true,
      sendTo: '',
      community_partner: [],
      isSuperUser: false,
      previewDate: null,
      retryCount: 1
    }
  }
  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.fetchActiveCommunity()
  }
  public render() {
    return (
      <div className="email-design">
        {this.state.isLoading ? <Loading size="32"/> :
        <div className="email-container email-preview">
          <div className="preview" style={{backgroundColor: this.props[EmailAttributes.emailBodyBackgroundColor]}}>
            {this.state.isHtmlPreviewLoading ?
            <Loading size="32"/> :
            <div className="preview-html" dangerouslySetInnerHTML={{
              __html: this.state.htmlPreview,
            }}/>}
          </div>
          <div className="settings">
            <div className="editor">
            <div className="segment-code-container">
              <LoadSegmentCodes setSegmentCode={this.setSegmentCode} setSegmentData={this.setSegmentData}/>
            </div>
            <SendPreview data={this.props}
              dirty={this.state.isDirty}
              sendNewsletter={this.sendNewsletter}
              sendTo={this.state.sendTo}
              changeSendTo={this.changeSendTo}
              name={MenuSections.sendPreview}
              previewDateChange={this.previewDateChange}
              articlePreviewError={this.state.articlePreviewError}
              onChange={(field: string, value: string) => this.propertyChanged(field, value)} />
            </div>
          </div>
        </div>
        }
      </div>)
  }

  private fetchActiveCommunity = () => {
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.role),
    ])
      .then(([activeCommunity, person, role]) => {
        if (!activeCommunity.communityInfo || !activeCommunity.communityInfo.data) {
          if (this.state.retryCount <= RETRY_LIMIT) {
            setTimeout(() => {
              this.setState({
                retryCount: this.state.retryCount + 1,
              }, () => {
                this.fetchActiveCommunity()
              })
            }, RETRY_WAIT_TIME)
          } else {
            this.setState({
              isLoading: false,
            }, () => {
              this.context.store.dispatch(Flash.showFlashError(ACTIVE_COMMUNITY_NOT_FOUND_ERROR))
            })
          }
        } else  {
          this.emailLayoutId = activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id
          const nextIssueDate = activeCommunity.nextIssue ? activeCommunity.nextIssue.date : null
          let previewDate = new Date()
          if (nextIssueDate) {
            // there is nextIssueDate available then use nextIssueDate as previewDate
            previewDate = new Date(nextIssueDate)
          }
          this.loadRecord(activeCommunity.communityId, this.emailLayoutId)
            .then(() => {
              this.setState({
                communityId: activeCommunity.communityId,
                communityRecordId: activeCommunity.data.community_id,
                emailTemplateId: activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].value,
                isLoading: false,
                previewDate,
                sendTo: person.email,
                isSuperUser: role === Roles.super_admin,
              }, () => this.loadEmailPreview({previewDate}))
            })
        }
      })
  }
  private previewDateChange = (previewDate: Date) => {
    const emailPreview: IEmailPreview = {
      previewDate,
      segmentCode: this.state.segmentCode ? this.state.segmentCode.value : null,
    }
    this.setState({
      previewDate,
    })
    this.loadEmailPreview(emailPreview)
  }
  private changeSendTo = (value: string) => {
    return this.setState({ sendTo: value })
  }

  private sendNewsletter = (email: string, date: Date = null) => {
    const separator = emailSeparator(email)
    if (!isEmpty(separator)) {
      email.split(separator).forEach((emailId) => {
        if (!isEmpty(emailId)) {
          this.sendEmail(emailId.trim(), date)
        }
      })
    } else {
      this.sendEmail(email, date)
    }
  }

  private sendEmail = (email: string, date: Date = null) => {
     const payload: any = {
       CommunityIdentifier: this.state.communityId,
       OverrideEmail: email,
       UseTemplateCache: false,
       sendModeDescription: PREVIEW_MODE.description,
     }
     if (this.state.segmentCode && this.state.segmentCode.value) {
      payload.segmentCode = this.state.segmentCode.value
    }
     if (date) {
       // FeatureDate has to be a community localized UTC datetime string
       payload.FeatureDate = formatDateTimeForLambda(date)
     }

     Utils.sendEmail(this.state.communityId, email, payload, date)
      .then((success) => {
        this.context.store.dispatch(Flash.showFlashMessage(EMAIL_SENT_SUCCESSFULLY))
      })
      .catch((error) => {
        this.context.store.dispatch(Flash.showFlashError(EMAIL_SENT_FAILURE))
      })
  }

  private loadEmailPreview(emailPreview: IEmailPreview) {
    this.setState({
      isHtmlPreviewLoading: true,
    })

    const url: string = `${AjaxWrapper.getServerUrl()}/email/preview/${this.state.communityId}/${this.state.emailTemplateId}`

    const payload: any = {
      issueId: -1,
      sendModeDescription: PREVIEW_MODE.description,
    }
    if (emailPreview.previewDate) {
      payload.featureDate = formatDateTimeForLambda(emailPreview.previewDate)
    }
    if (emailPreview.segmentCode) {
      payload.segmentCode = emailPreview.segmentCode
    }
    return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
      .then((response) => {
        this.setState({
          isHtmlPreviewLoading: false,
          articlePreviewError: response.data.error,
          htmlPreview: response.data.body,
        })
      })
      .catch((error) => {
        this.setState({
          htmlPreview: error.message,
          isHtmlPreviewLoading: false,
        })
      })
  }

  private setSegmentData = (data: any) => {
    this.setState({
      community_partner: data,
    })
  }

  private setSegmentCode = (code: any) => {
    this.setState({
      segmentCode: code,
    })
    const emailPreview: IEmailPreview = {
      previewDate: this.state.previewDate,
      segmentCode: code.value,
    }
    this.loadEmailPreview(emailPreview)
  }
}

export const EmailPreview = GenericRedux.registerNewComponent<any>(
  EmailPreviewComponent,
  'email_preview',
  {})
