import { DatePicker } from '@progress/kendo-react-dateinputs'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import * as GenericRedux from 'generic/genericRedux'
import { emailSeparator, validateEmail } from 'generic/utility'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { Button, Input } from 'reactstrap'
import { loadRecaptcha } from 'recaptcha'
import { DATEPICKER_DATE_FORMAT } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { DEFAULT_TIMEZONE } from 'shared_server_client/constants'
import { validateTz } from 'shared_server_client/dates'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { EditSectionProps } from '../components'
import {
  AI_RECOMMENDED_SUBJECT,
  BOOSTED_SUBJECT,
  EmailAttributes,
  getAttribute,
  MAX_EMAILS_ALLOWED_FOR_SEND,
  MAX_TIME_DELAY_FOR_EMAIL_SEND,
} from '../constants'
import '../styles.scss'
import { hasScheduledModule } from '../utils'

interface Props {
  sendNewsletter: any
  sendTo: string,
  changeSendTo: any,
  dirty: boolean,
  articlePreviewError: string,
  previewDateChange?: any,
}

interface SendPreviewState {
  hasFeatureAccess: boolean
  isDelaySend: boolean
  isLoading: boolean
  nextIssueDate: string
  pathname: string
  person: any
  previewDate: Date
  subjectType: string
  timezone: string
}

type SendProps = GenericRedux.DatasetComponentProps<any> & EditSectionProps & Props

export class SendPreview extends React.Component<SendProps, SendPreviewState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: SendProps) {
    super(props)
    this.state = {
      hasFeatureAccess: false,
      isDelaySend: false,
      isLoading: true,
      nextIssueDate: null,
      person: {},
      previewDate: null,
      subjectType: 'custom',
      timezone: DEFAULT_TIMEZONE,
      pathname: window.location.pathname,
    }
    this.onEmailChange = this.onEmailChange.bind(this)
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.params),
    ]).then(([activeCommunity, person, params]) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      const { date } = params
      const  nextIssueDate = activeCommunity.nextIssue ? activeCommunity.nextIssue.date : null
      const timezone = activeCommunity.data && activeCommunity.data.company_time_zone
        ? activeCommunity.data && activeCommunity.data.company_time_zone
        : DEFAULT_TIMEZONE

      const formattedTz = validateTz(timezone)
      let previewDate = new Date()
      if (date) {
        previewDate = new Date(date) // we got previewDate from param. Just use it
      } else if (nextIssueDate) {
        // there is nextIssueDate available then use nextIssueDate as previewDate
        previewDate = utcToZonedTime(nextIssueDate, formattedTz)
      }

      const stateUpdate: any = {
        hasFeatureAccess: avlFeatures.indexOf(BillingPlanDetailCode.SCHEDULED_CONTENT) > -1,
        isLoading: false,
        nextIssueDate,
        person: person || {},
        previewDate,
        timezone,
      }
      if (!this.props.data.email_subject || this.props.data.email_subject.includes(AI_RECOMMENDED_SUBJECT)) {
        stateUpdate.subjectType = 'ai'
      }
      if (this.props.data.email_subject && this.props.data.email_subject.includes(BOOSTED_SUBJECT)) {
        stateUpdate.subjectType = 'boosted'
      }
      this.setState(stateUpdate)
      loadRecaptcha()
    })
  }

  public render() {
    return <div className="send-preview-tab">
      <div>
        {this.canShowDateSelector() &&
          <div className="date-picker-container">
            <div className="title">Preview Date</div>
            <DatePicker
              className="schedule-date-picker"
              format={DATEPICKER_DATE_FORMAT}
              value={this.state.previewDate}
              onChange={this.previewDateChange}
            />
          </div>}
      </div>
      <div className="section">
        <div className="top-block">
          <span className="big-title">
            Send Test
          </span>
          <br />
          <div className="title send-to">Send to:
            <Input value={this.props.sendTo || ''}
              onChange={this.onEmailChange} /><br></br>
          </div>
        </div>
        <div className="send-test-newsletter">
          {this.props.dirty ?
            <div>
              <p>
                Please save your changes before sending your newsletter!
              </p>
            </div> :
            <div className="send-submit">
              {!this.props.articlePreviewError
                ? this.state.isLoading
                  ? <div className="send-submit"><Loading size="32" /></div>
                  : <div>
                    <Button disabled={this.isSendButtonDisabled()} onClick={this.sendNewsletter}>
                      Send
                    </Button>
                    {this.state.pathname === DashboardMenuOption.emailDesign &&
                    <div className="send-email-warning">
                      <p>
                        Send an example newsletter to view your design elements.
                      </p>
                    </div>
                    }
                  </div>
                :
                <div>
                  <p>
                    {this.props.articlePreviewError}
                  </p>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  }

  private onEmailChange(e) {
    const separator = emailSeparator(e.target.value)

    if (isEmpty(separator) || e.target.value.split(separator).length <= MAX_EMAILS_ALLOWED_FOR_SEND) {
      this.props.changeSendTo(e.target.value)
    }
  }

  private canShowDateSelector() {
    const hasScheduled: boolean = hasScheduledModule(this.props.data.template_modules) || this.isCustomTemplate()
    const hasAccess: boolean = this.state.hasFeatureAccess
    const isLoading: boolean = this.state.isLoading

    return !isLoading && hasScheduled && hasAccess
  }

  private isCustomTemplate = () => {
    return (getAttribute(this.props.data, EmailAttributes.emailTemplate) || '').startsWith('custom:')
  }

  private sendNewsletter = () => {
    this.setState({ isLoading: true, isDelaySend: true }, () => {
      let sendDate = this.state.previewDate
      if (this.state.previewDate && this.state.nextIssueDate) {
        sendDate = zonedTimeToUtc(this.state.previewDate, this.state.timezone)
      }
      this.props.sendNewsletter(this.props.sendTo, sendDate)
      setTimeout(() => {
        this.setState({ isDelaySend: false })
      }, MAX_TIME_DELAY_FOR_EMAIL_SEND * 1000)
    })
    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 2000)
  }

  private previewDateChange = (e: any) => {
    this.setState({
      previewDate: e.value,
    }, () => this.props.previewDateChange && this.props.previewDateChange(e.value))
  }

  private isSendButtonDisabled = (): boolean => {
    return !this.validateEmailAddress() || this.state.isDelaySend
  }
  private validateEmailAddress = (): boolean => {
    let isEmailValid: boolean = true
    const separator = emailSeparator(this.props.sendTo)
    if (!isEmpty(separator)) {
      this.props.sendTo.split(separator).forEach((email) => {
        if (isEmailValid && !isEmpty(email) && !validateEmail(email.trim())) {
          isEmailValid = false
        }
      })
    } else {
      return validateEmail(this.props.sendTo)
    }
    return isEmailValid
  }
}
